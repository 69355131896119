import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './App.css';
import customIcon from './assets/pinUbicacion.png'; // Ícono del usuario
import sismoIcon from './assets/sismoIcon.png'; // Ícono de sismos
import { Drawer, List, ListItem, ListItemText, AppBar, Toolbar, IconButton, Typography, Snackbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// API endpoint para obtener los sismos más recientes
const earthquakeAPI = 'https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/all_day.geojson';

// Componente para actualizar el zoom y centro del mapa cuando cambia la ubicación
const SetViewOnLocationChange = ({ location }) => {
  const map = useMap();
  useEffect(() => {
    if (location) {
      map.setView(location, 15); // Actualiza el centro y el zoom a la ubicación del usuario
    }
  }, [location, map]);

  return null;
};

const App = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [earthquakes, setEarthquakes] = useState([]); // Estado para almacenar los sismos
  const [simulatedEarthquakes, setSimulatedEarthquakes] = useState([]); // Estado para almacenar solo los sismos simulados
  const [currentTime, setCurrentTime] = useState(new Date()); // Estado para la hora actual
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [newQuake, setNewQuake] = useState(null);
  const [notifiedQuakes, setNotifiedQuakes] = useState(new Set());

  // Efecto para solicitar la ubicación del usuario
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation([position.coords.latitude, position.coords.longitude]);
        },
        (error) => {
          setErrorMessage("No se pudo obtener la ubicación con precisión.");
        },
        { enableHighAccuracy: true }
      );
    } else {
      setErrorMessage("La geolocalización no está disponible en este navegador.");
    }
  }, []);

  // Efecto para obtener sismos de la API
  useEffect(() => {
    const fetchEarthquakes = async () => {
      try {
        const response = await fetch(earthquakeAPI);
        const data = await response.json();
        const newQuakes = data.features.filter(quake => !notifiedQuakes.has(quake.id));
        if (newQuakes.length > 0) {
          setEarthquakes(data.features); // Guardar los sismos en el estado
          setNewQuake(newQuakes[0]); // Solo notificar el primer nuevo sismo
          setSnackbarOpen(true); // Abrir snackbar
          newQuakes.forEach(quake => notifiedQuakes.add(quake.id));
          setNotifiedQuakes(new Set(notifiedQuakes));
        }
      } catch (error) {
        console.error('Error al obtener los sismos:', error);
      }
    };

    fetchEarthquakes();

    // Actualizar sismos cada 10 segundos
    const intervalId = setInterval(fetchEarthquakes, 10000); // 10,000 milisegundos = 10 segundos

    return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar el componente
  }, [notifiedQuakes]);

  // Efecto para actualizar la hora cada segundo
  useEffect(() => {
    const clockInterval = setInterval(() => {
      setCurrentTime(new Date()); // Actualiza la hora
    }, 1000);

    return () => clearInterval(clockInterval); // Limpia el intervalo
  }, []);

  // **Generar sismos simulados en estados sísmicos**
  useEffect(() => {
    const sismicStates = [
      {
        state: 'Guerrero',
        coordinatesRange: { latMin: 16.0, latMax: 18.5, lonMin: -101.5, lonMax: -98.5 }
      },
      {
        state: 'Oaxaca',
        coordinatesRange: { latMin: 15.5, latMax: 18.0, lonMin: -98.0, lonMax: -94.5 }
      },
      {
        state: 'Michoacán',
        coordinatesRange: { latMin: 18.0, latMax: 20.5, lonMin: -103.0, lonMax: -100.0 }
      },
      {
        state: 'Chiapas',
        coordinatesRange: { latMin: 14.5, latMax: 17.0, lonMin: -94.5, lonMax: -90.5 }
      },
      {
        state: 'Jalisco',
        coordinatesRange: { latMin: 19.5, latMax: 21.5, lonMin: -105.0, lonMax: -102.0 }
      }
    ];

    // Función para generar coordenadas aleatorias dentro de los límites del estado
    const getRandomCoordinates = (range) => {
      const lat = Math.random() * (range.latMax - range.latMin) + range.latMin;
      const lon = Math.random() * (range.lonMax - range.lonMin) + range.lonMin;
      return [lat, lon];
    };

    const generateSimulatedEarthquakes = () => {
      const newSimulatedEarthquakes = sismicStates.map((stateData, index) => {
        const [lat, lon] = getRandomCoordinates(stateData.coordinatesRange);
        return {
          id: `simulated-${index}-${Date.now()}`,
          geometry: {
            coordinates: [lon, lat]
          },
          properties: {
            mag: (Math.random() * 2 + 4).toFixed(1), // Magnitud simulada entre 4.0 y 6.0
            place: `Sismo simulado en ${stateData.state}`,
            time: Date.now()
          }
        };
      });

      console.log("Generando sismos simulados: ", newSimulatedEarthquakes); // Log para verificar los sismos simulados

      setSimulatedEarthquakes((prevSimulatedQuakes) => {
        let updatedSimulatedQuakes = [...prevSimulatedQuakes, ...newSimulatedEarthquakes];

        // Si hay más de 70 sismos simulados, eliminar los últimos 20 generados
        if (updatedSimulatedQuakes.length > 70) {
          updatedSimulatedQuakes = updatedSimulatedQuakes.slice(0, -20); // Eliminar los últimos 20
        }

        return updatedSimulatedQuakes;
      });
    };

    generateSimulatedEarthquakes(); // Generar sismos al cargar el componente

    const intervalId = setInterval(generateSimulatedEarthquakes, 5000); // Cada 5 segundos

    return () => clearInterval(intervalId);
  }, []);

  // Mezclar sismos reales y simulados para mostrar en el mapa
  const combinedEarthquakes = [...earthquakes, ...simulatedEarthquakes];

  // Coordenadas de México por defecto
  const mexicoCoordinates = [23.6345, -102.5528];
  const zoomLevel = 5; // Zoom por defecto antes de obtener la ubicación

  // Definir los íconos personalizados con Leaflet
  const customMarkerIcon = new L.Icon({
    iconUrl: customIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const sismoMarkerIcon = new L.Icon({
    iconUrl: sismoIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  // Función para abrir o cerrar el drawer
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ color: 'red' }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Monitoreo de sismos en tiempo real
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* Mostrar la hora actual en el AppBar */}
            <Typography variant="h6">
              {currentTime.toLocaleString('es-MX', {
                timeZone: 'America/Mexico_City',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem button>
            <ListItemText primary="Mapa de sismos" />
          </ListItem>
          <ListItem button component="a" href="https://www.sismologico.unam.mx/">
            <ListItemText primary="Sismológico Nacional" />
          </ListItem>
          <ListItem button component="a" href="https://www.gob.mx/cenapred">
            <ListItemText primary="CENAPRED" />
          </ListItem>
          <ListItem button component="a" href="https://www.preparados.gob.mx/">
            <ListItemText primary="Guía de seguridad" />
          </ListItem>
        </List>
      </Drawer>

      {errorMessage && <p>{errorMessage}</p>}

      <div id="map-container">
        <MapContainer
          center={userLocation || mexicoCoordinates}
          zoom={zoomLevel}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/">CARTO</a>'
          />
          {/* Actualizar la vista cuando se obtiene la ubicación */}
          <SetViewOnLocationChange location={userLocation} />
          
          {/* Mostrar marcador en la ubicación del usuario */}
          {userLocation && (
            <Marker position={userLocation} icon={customMarkerIcon}>
              <Popup>
                Estás aquí: [{userLocation[0]}, {userLocation[1]}]
              </Popup>
            </Marker>
          )}

          {/* Mostrar los sismos (reales + simulados) en el mapa */}
          {combinedEarthquakes.map((quake) => (
            <Marker
              key={quake.id}
              position={[quake.geometry.coordinates[1], quake.geometry.coordinates[0]]}
              icon={sismoMarkerIcon}
            >
              <Popup>
                <strong>Lugar:</strong> {quake.properties.place}<br />
                <strong>Magnitud:</strong> {quake.properties.mag}<br />
                <strong>Hora:</strong> {new Date(quake.properties.time).toLocaleTimeString('es-MX')}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>

      {/* Notificación emergente (Snackbar) */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={`Nuevo sismo detectado: ${newQuake?.properties.place}, Magnitud: ${newQuake?.properties.mag}`}
      />
    </div>
  );
};

export default App;
